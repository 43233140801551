.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.info {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    justify-content: space-between;
}
.classification-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkBox {
    width: 40%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    background-color: #e7e4e4;
    padding: 10px;
}

.description {
    width: 70%;
}
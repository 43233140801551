.App {
    background-color: white;
    font-family: 'Acherus Grotesque Regular';
}

.vt-header {
  background-color: #861F41;
  align-items: center;
  display: flex;
}

.vt-header img {
  height: 56px; /* Set image height relative to the font size */
  width: auto; /* Maintain aspect ratio */
  padding: 0;
  margin-top: 0;
  margin-left: 20px;
}

.vt-header .vt-title {
  flex: 1;
  padding: 10px;
  font-size: 18px; /* Set text font size */
  font-family: 'Acherus Grotesque Bold';
  text-align: center;
  color: white;
}

.vt-header-content {
  /* background-color:#d0d5da; */
  background-color:#d2d7dc;
  padding: 20px 50px;
  font-size: 16px;
  /* text-align: center; */
}

.vt-header-content ul {
  padding: 5px 2px 0px 10px;
}

.vt-header-content li {
  padding: 2px 2px 2px 0px;
}

.vt-header-content li .firstWord{
  font-family: 'Acherus Grotesque Bold';
}

#objective {
  margin-top: 10px;
  padding: 3px;
  display: flex;
  justify-content:first baseline;
}

/* nav-bar styles */

.collapsible {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px;
}

.collapsible-header {
  align-items: flex-start;
  text-align: left;
  cursor: pointer;
  user-select: none;
  font-family: 'Acherus Grotesque Bold';
}

.collapsible-header span {
  margin-right: 5px;
}

.collapsible-content {
  margin-left: 15px;
  margin-right: 15px;
  text-align: justify;
}

.collapsible-content ul {
  padding: 5px 5px 0px 10px;
}

.collapsible-content li {
  padding: 2px 2px 2px 0px;
}

.collapsible-content li .firstWord {
  font-family: 'Acherus Grotesque Bold';
}

h4 {
  font-family: 'Acherus Grotesque Bold';
}

#nav{
  background-color: #E5751F; 
  color:white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  /* border: 1px solid black; */
  margin-top: 2px;
}

#nav ul {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 0px;
}


#nav a:link {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

#nav a:visited {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

.navbar {
  margin-top: 2px;
  background-color: #E5751F;
  color: white;
  align-items: center;
}
  
.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  align-items: center;
}
  
.navbar-items {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.navbar-item {
  margin: 0 10px;
}
  
.navbar-item a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.navbar-item a:hover {
  color: black;
}

#tick {
  color: green;
  font-size: large;
  font-weight: bold;
}

img {
  vertical-align: middle;
  border-style: none;
}

ul {
  list-style: none;
  padding: 0;
}
ol, ul {
  margin-bottom: 0;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

*, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
div {
    display: block;
}


/* hover text */
.card {
  position: relative;
}

.card:hover {
  background-color: #e0e0e0;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease;
}

.card:hover .hover-text {
  visibility: visible;
  opacity: 1;
}

.color-red {
  color: red;
}

.color-orange {
  color: orange;
}

.color-green {
  color: green;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

#logs {
  /* Padding: top right bottom left */
  padding: 20px 0px 10px 10px;
}

.collapsible-log-header {
  cursor: pointer;
  font-family: 'Acherus Grotesque Bold';
}

.collapsible-log-header span {
  margin-right: 5px;
}

.collapsible-log-content {
  padding: 10px 0px 10px 20px;
}

/* List inside collapsible content */
.collapsible-log-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.collapsible-log-content li {
  margin: 5px 0px;
}

.collapsible-log-content ul ul {
  list-style-type: none; /* Remove default bullets for nested lists */
}

ul ul li {
  position: relative;
  padding-left: 1em;
}

ul ul li::before {
  content: '○'; /* Hollow bullet (Unicode character) */
  position: absolute;
  left: 0;
  top: 0;
}

.tableContainer::-webkit-scrollbar {
  width: 12px;
}

.tableContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom scrollbar for the rest of the page */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #e1e1e1;
}

body::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 10px;
  border: 3px solid #e1e1e1;
}

body::-webkit-scrollbar-thumb:hover {
  background: #222;
}

/* For Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #444 #e1e1e1;
}
.flexContainer {
  display: flex;
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 14px;
  justify-content: space-between;
}

.headerCell {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.switchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
footer {
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 40px 0 50px 0;
  line-height: 2em;
  margin: 0;
  font-family: Acherus,sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  left: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

p {
  margin-top: 0;
}

dl, ol, ul {
  margin-top: 0;
}

ol, ul {
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.vt-footer-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.vt-footer-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: .8em;
  padding: 20px 0;
  width: 100%;
  line-height: 1.5;
}

.vt-embed-map {
  position: relative;
  padding-bottom: 0;
  width: 200px;
  margin-bottom: 2em;
}

.vt-map-image {
  display: block; /* remove any white space after the image */
  position: relative;
  width: 100%;
}

.vt-footer-infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.vt-footer-logoWrapper {
  display: block;
  position: relative;
  width: 100%;
  max-width: 160px;
}

.vt-footer-logo {
  display: block;
  position: relative;
  margin-bottom: 1em;
  width: 100%;
  max-width: 100%;
}

.vt-footer-info address,.vt-footer-info p {
  margin-bottom: 0;
  letter-spacing: .04em;
  font-weight: 300;
}

.vt-footer-info .vt-footer-directions,.vt-footer-info .vt-footer-locations,.vt-footer-contact{
  font-size: 1em;
  margin-left: 2em;
}

.vt-footer-info .footer-info-link {
  font-family: "gineso-condensed", sans-serif;
  font-size: 1em;
  font-size-adjust: .4;
  font-style: italic;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: .125em;
  line-height: 1.4;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.vt-footer-info .vt-footer-address {
  margin-bottom: 2em;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  /* color: #e87722;
  stroke: white;
  stroke-width: 10px; */
}

.vt-footer-rowSpacer {
  display: none;
  flex-basis: 0
}

.vt-footer-links {
  display: block;
  width: 100%;
}

.vt-footer-linksCol {
  width: 100%;
}

.vt-footer-linksCol li {
  display: inline;
  text-align: center;
}

footer .vt-footer-link {
  display: block;
  font-family: Acherus,sans-serif;
  font-size: .8em;
  font-weight: 300;
  font-style: normal;
  line-height: 1.4;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: var(--vt-white);
  width: auto;
  margin-bottom: 1em;
}

.vt-remember {
  display: inline-block;
  vertical-align: text-bottom;
}

.vt-remember::before {
  content: url("https://www.assets.cms.vt.edu/images/vt-2016-ribbon.svg");
  display: block;
  line-height: 2em;
  position: relative;
  width: .8em;
  height: 1.5em;
  margin-right: .5em;
  float: left;
}

.vt-footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-family: Acherus,sans-serif;
  border-top: 1px solid #5e6062;
  padding: 20px 0;
  margin-top: 20px;
}

#vt_footer_copyright {
  display: block;
  padding-left: 0;
  margin-right: 15px;
  width: 100%;
  text-align: center;
}

.vt-copyright {
  display: block;
  font-family: Acherus,sans-serif;
  font-size: .66666667em;
  font-weight: 300;
  font-style: normal;
  letter-spacing: .125em;
  line-height: 1.4;
  margin: 0;
  color: var(--vt-white);
}

.follow-virginia-tech-icons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
  width: 100%;
}

.follow-virginia-tech-icons a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1.5em;
  width: 1.5em
}

.follow-virginia-tech-icons a:last-of-type {
    padding-right: 0
}

/* kebab-case is recommended */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

footer a {
  color: var(--vt-white);
  text-decoration:none;
  background-color:transparent;
  cursor: pointer;
  position: relative;
}

footer a:hover,
footer a:active {
  color: #ccc;
  text-decoration:underline;
}

li a img {
  display: inline-block;
  width: 1em;
  height: 1em;
}

@media screen and (min-width: 768px) {
  footer {
    padding: 40px 35px 0 35px;
  }
  .vt-footer-row {
    flex-direction:row;
    justify-content: space-between;
    align-items: stretch;
  }
  .vt-footer-info {
    align-items: flex-start;
  }
  .vt-embed-map {
    align-self: center;
    width: 100%;
    max-width: 330px;
    padding-bottom: 0;
  }
  .vt-footer-links {
    padding-left: 1.25em;
  }
  .vt-footer-linksCol li {
    text-align:left;
    width: 50%;
    float: left;
  }
  .vt-footer-bottom {
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 20px;
  }
  #vt_footer_copyright {
    text-align: left;
  }
  .follow-virginia-tech-icons {
    justify-content:flex-end;
    margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .vt-footer-info {
    flex-basis: 50%;
    flex-direction: row;
  }
  .vt-embed-map {
    margin-bottom: 0;
    max-width: 330px;
  }
  .vt-footer-links {
    flex-basis: 60%;
  }
  footer .vt-footer-link {
    margin-bottom: .5em
  }
}

@media screen and (min-width: 1300px) {
  .vt-footer-rowSpacer {
    display: block;
    flex-basis: 16.66666667%;
  }
  .vt-footer-linksCol li {
    width:33.33333334%;
  }
}

@media screen and (max-width: 767px) {
  .vt-remember::before {
    float: none;
  }
}

@media all and (-ms-high-contrast: none) {
  .vt-remember::before {
    vertical-align: text-bottom;
  }
}
